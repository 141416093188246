import React, { Component } from 'react'

class Filter extends Component {

  handler = (filter) => {
    this.props.updateFilter(filter);
  }


  render() {

    return (
      <div className="mb-3">
        <label>Filter:</label>
        <ul>
          <li style={{ color: this.props.filter === '' ? 'red' : ''}} onClick={() => this.handler('')}>All</li>
          <li style={{ color: this.props.filter === 'Featured' ? 'red' : ''}} onClick={() => this.handler('Featured')}>Featured</li>
          <li style={{ color: this.props.filter === 'Travel' ? 'red' : ''}} onClick={() => this.handler('Travel')}>Travel</li>
          <li style={{ color: this.props.filter === 'Nature' ? 'red' : ''}} onClick={() => this.handler('Nature')}>Nature</li>
          <li style={{ color: this.props.filter === 'Art' ? 'red' : ''}} onClick={() => this.handler('Art')}>Art</li>
        </ul>
      </div>
    )
  }
}

export default Filter;
