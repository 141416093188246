import React, { Component } from 'react'

class Search extends Component {

  handler = (search) => {
    this.props.updateSearch(search.target.value);
  }


  render() {

    return (
      <div className="mb-3">
        <label htmlFor="search">Search:</label>
        <input id="search" type="text" onChange={this.handler} value={this.props.search} />
      </div>
    )
  }
}

export default Search;
