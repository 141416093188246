import React, { Component } from 'react'

class Navbar extends Component {
  render() {
    return (
      <div className="navbar my-5">
        {/* <div className="order-last ml-auto">
          <h1>Sean Fowler</h1>
        </div>
        <div>
          Work / About
        </div> */}
        <h4 className="heading">Sean <span style={{color: 'red'}}>Fowler</span></h4>
        <p className="sub-heading">Photography portfolio</p>
      </div>
    )
  }
}

export default Navbar;