import React, { Component } from 'react'

class SortBy extends Component {

  handler = (sortBy) => {
    this.props.updateSortBy(sortBy.target.value);
  }


  render() {

    return (
      <div>
        <label htmlFor="sort-by">Sort By:</label>
        <select id="sort-by" onChange={this.handler} defaultValue={this.props.sortBy} value={this.props.sortBy}>
          <option value="Country (asc)">Country (asc)</option>
          <option value="Country (desc)">Country (desc)</option>
          <option value="Date (asc)">Date (asc)</option>
          <option value="Date (desc)">Date (desc)</option>
          <option value="Title (asc)">Title (asc)</option>
          <option value="Title (desc)">Title (desc)</option>
        </select>
      </div>
    )
  }
}

export default SortBy;
