import React, { Component } from 'react';
import ImageCard from './components/ImageCard';
import Navbar from './components/layouts/Navbar';
import Search from './components/Search';
import Filter from './components/Filter';
import SortBy from './components/SortBy';

import Masonry from 'react-masonry-css';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      images: [
        {
          title: 'Duomo di Milano',
          location: 'Milan, Italy',
          date: new Date('2019/09/05 14:08'),
          description: '<p>Duomo di Milano (Milan Cathedral) is the cathedral church of Milan, Lombardy, Italy. Dedicated to the Nativity of St Mary (Santa Maria Nascente), it is the seat of the Archbishop of Milan.</p>',
          url: '/images/duomo_di_milano.jpg',
          flag: 'it',
          tags: ['Featured', 'Travel']
        },
        {
          title: 'Santa Maria delle Grazie',
          location: 'Milan, Italy',
          date: new Date('2019/09/06 10:28'),
          description: '<p>Santa Maria delle Grazie (Holy Mary of Grace) is a church and Dominican convent in Milan, northern Italy, and a UNESCO World Heritage Site. The church contains the mural of The Last Supper by Leonardo da Vinci, which is in the refectory of the convent.</p>',
          url: '/images/santa_maria_delle_grazie.jpg',
          flag: 'it',
          tags: ['Travel']
        },
        {
          title: 'Bosco Verticale',
          location: 'Milan, Italy',
          date: new Date('2019/09/06 13:55'),
          description: '<p>Bosco Verticale (Vertical Forest) is a pair of residential towers in the Isola district of Milan, Italy. They have a height of 111 metres (364 ft) and 76 metres (249 ft) and contain more than 900 trees. Within the complex is an 11-storey office building; its facade does not include plants.</p>',
          url: '/images/bosco_verticale.jpg',
          flag: 'it',
          tags: ['Travel', 'Nature']
        },
        {
          title: 'Rosenborg Slot',
          location: 'Copenhagen, Denmark',
          date: new Date('2019/10/08 12:28'),
          description: '<p>Rosenborg Slot (Rosenborg Castle) is a renaissance castle located in Copenhagen, Denmark. The castle was originally built as a country summerhouse in 1606 and is an example of Christian IV\'s many architectural projects. It was built in the Dutch Renaissance style, typical of Danish buildings during this period, and has been expanded several times, finally evolving into its present condition by the year 1624.</p>',
          url: '/images/rosenborg_castle.jpg',
          flag: 'dk',
          tags: ['Travel']
        },
        {
          title: 'Nyhavn Harbour',
          location: 'Copenhagen, Denmark',
          date: new Date('2019/10/08 19:03'),
          description: '<p>Nyhavn (New Harbour) is a 17th-century waterfront, canal and entertainment district in Copenhagen, Denmark. Stretching from Kongens Nytorv to the harbour front just south of the Royal Playhouse, it is lined by brightly coloured 17th and early 18th century townhouses and bars, cafes and restaurants. The canal harbours many historical wooden ships.</p>',
          url: '/images/nyhavn_harbour.jpg',
          flag: 'dk',
          tags: ['Travel']
        },
        {
          title: 'Gol stavkyrkje',
          location: 'Oslo, Norway',
          date: new Date('2019/11/03 16:18'),
          description: '<p>Gol stavkyrkje (Gol Stave Church) is a stave church originally from Gol in the traditional region of Hallingdal in Buskerud county, Norway. The reconstructed church is now a museum and is now located in the Norwegian Museum of Cultural History at Bygdøy in Oslo, Norway.</p>',
          url: '/images/uvdal_stavkirke.jpg',
          flag: 'no',
          tags: ['Featured', 'Travel']
        },
        {
          title: 'Christmas Lights',
          location: 'Lisbon, Portugal',
          date: new Date('2019/11/09 19:16'),
          description: '<p>Armazéns do Chiado, a department store situated on R. Nova do Almad, a short walk from Elevador de Santa Justa, played host as the location for hundreds of people to congregate to celebrate the turning on of 2019\'s christmas lights.</p>',
          url: '/images/christmas_lights.jpg',
          flag: 'pt',
          tags: ['Featured', 'Travel']
        },
        {
          title: 'Elevador de Santa Justa',
          location: 'Lisbon, Portugal',
          date: new Date('2019/11/10 10:30'),
          description: `<p>The Elevador de Santa Justa (Santa Justa Lift), is an elevator, or lift, in the historic center of Lisbon, Portugal. It connects the lower streets of the Baixa with the higher Largo do Carmo (Carmo Square).</p> 
            <p>Since its construction the Lift has become a tourist attraction for Lisbon as, among the urban lifts in the city, Santa Justa is the only remaining vertical one.</p>`,
          url: '/images/elevador_de_santa_justa.jpg',
          flag: 'pt',
          tags: ['Featured', 'Travel']
        },
        {
          title: 'Padrao dos Descobrimentos',
          location: 'Lisbon, Portugal',
          date: new Date('2019/11/10 15:33'),
          description: '<p>Padrão dos Descobrimentos (Monument of the Discoveries) is a monument on the northern bank of the Tagus River estuary, in Santa Maria de Belém, Lisbon. Located along the river where ships departed to explore and trade with India and the Orient, the monument celebrates the Portuguese Age of Discovery during the 15th and 16th centuries.</p>',
          url: '/images/padrao_dos_descobrimentos.jpg',
          flag: 'pt',
          tags: ['Featured', 'Travel']
        },
        {
          title: 'Prague Castle',
          location: 'Prague, Czech Republic',
          date: new Date('2020/01/18 12:55'),
          description: '<p>Prague Castle is a castle complex in Prague, Czech Republic, built in the 9th century. It is the official office of the President of the Czech Republic. The castle was a seat of power for kings of Bohemia, Holy Roman emperors, and presidents of Czechoslovakia. The Bohemian Crown Jewels are kept within a hidden room inside it.</p>',
          url: '/images/prague_castle.jpg',
          flag: 'cz',
          tags: ['Featured', 'Travel']
        },
        {
          title: 'Cimitero Monumentale',
          location: 'Milan, Italy',
          date: new Date('2019/09/06 12:47'),
          description: '<p>The Cimitero Monumentale (Monumental Cemetery) is one of the two largest cemeteries in Milan, Italy, the other one being the Cimitero Maggiore. It is noted for the abundance of artistic tombs and monuments.</p>',
          url: '/images/cimitero_monumentale.jpg',
          flag: 'it',
          tags: ['Featured', 'Travel']
        },
        {
          title: 'Galleria Vittorio Emanuele II',
          location: 'Milan, Italy',
          date: new Date('2019/09/05 14:32'),
          description: '<p>The Galleria Vittorio Emanuele II is Italy\'s oldest active shopping mall and a major landmark of Milan, Italy. Housed within a four-story double arcade in the center of town, the Galleria is named after Victor Emmanuel II, the first king of the Kingdom of Italy. It was designed in 1861 and built by architect Giuseppe Mengoni between 1865 and 1877.</p>',
          url: '/images/galleria_vittorio_emanuele_2.jpg',
          flag: 'it',
          tags: ['Travel']
        },
        {
          title: 'Monumento a Leonardo da Vinci',
          location: 'Milan, Italy',
          date: new Date('2019/09/05 15:38'),
          description: `<p>The monument to Leonardo da Vinci is a commemorative sculptural group located in Piazza della Scala in Milan and inaugurated in 1872.</p>
            <p>The sculptures depict Leonardo da Vinci and four of his pupils: Marco d'Oggiono , Salaino , Cesare da Sesto , Giovanni Antonio Boltraffio.</p>`,
          url: '/images/monumento_a_leonardo_da_vinci.jpg',
          flag: 'it',
          tags: ['Travel']
        },
        {
          title: 'San Siro',
          location: 'Milan, Italy',
          date: new Date('2019/09/05 18:28'),
          description: '<p>The San Siro, officially known as Stadio Giuseppe Meazza, is a football stadium in the San Siro district of Milan, which is the home of AC Milan and Internazionale. It has a seating capacity of 75,923, making it one of the largest stadiums in Europe, and the largest in Italy.</p>',
          url: '/images/san_siro.jpg',
          flag: 'it',
          tags: ['Travel']
        },
        {
          title: 'Sforzesco Castle',
          location: 'Milan, Italy',
          date: new Date('2019/09/06 11:11'),
          description: '<p>Sforza Castle is in Milan, northern Italy. It was built in the 15th century by Francesco Sforza, Duke of Milan, on the remnants of a 14th-century fortification. Later renovated and enlarged, in the 16th and 17th centuries it was one of the largest citadels in Europe. Extensively rebuilt by Luca Beltrami in 1891–1905, it now houses several of the city\'s museums and art collections.</p>',
          url: '/images/sforzesco_castle.jpg',
          flag: 'it',
          tags: ['Travel']
        },
        {
          title: 'Amsterdam Street',
          location: 'Amsterdam, Netherlands',
          date: new Date('2019/12/22 16:57'),
          description: '',
          url: '/images/amsterdam_street.jpg',
          flag: 'nl',
          tags: ['Travel']
        },
        {
          title: 'Antonio Bernocchi',
          location: 'Milan, Italy',
          date: new Date('2019/09/06 13:05'),
          description: '<p>Antonio Bernocchi was an Italian industrialist, who built up a successful textile factory at Legnano, in Lombardy in northern Italy. He is buried in the Cimitero Monumentale di Milano, in a large monumental tomb by the architect Alessandro Minali and the sculptor Giannino Castiglioni.</p>',
          url: '/images/antonio_bernocchi.jpg',
          flag: 'it',
          tags: ['Travel', 'Art']
        },
        {
          title: 'Astronmical Clock',
          location: 'Prague, Czech Republic',
          date: new Date('2020/01/18 18:02'),
          description: '<p>The Prague Astronomical Clock, is a medieval astronomical clock located in Prague, the capital of the Czech Republic. The clock was first installed in 1410, making it the third-oldest astronomical clock in the world and the oldest clock still operating.</p>',
          url: '/images/astronomical_clock.jpg',
          flag: 'cz',
          tags: ['Travel']
        },
        {
          title: 'Belém Tower',
          location: 'Lisbon, Portugal',
          date: new Date('2019/11/10 15:53'),
          description: '<p>Belém Tower, officially the Tower of Saint Vincent is a 16th-century fortification located in Lisbon that served as a point of embarkation and disembarkation for Portuguese explorers and as a ceremonial gateway to Lisbon. It was built during the height of the Portuguese Renaissance, and is a prominent example of the Portuguese Manueline style.</p>',
          url: '/images/belem_tower.jpg',
          flag: 'pt',
          tags: ['Travel']
        },
        {
          title: 'Berlin Cathedral',
          location: 'Berlin, Germany',
          date: new Date('2020/03/08 11:56'),
          description: '<p>The Berlin Cathedral is a Protestant church and dynastic tomb on the Museum Island in Berlin. Built from 1894 to 1905 by order of German Emperor William II according to plans by Julius Raschdorff in Renaissance and Baroque Revival styles, the listed building is the largest Protestant church in Germany and one of the most important dynastic tombs in Europe.</p>',
          url: '/images/berlin_cathedral.jpg',
          flag: 'de',
          tags: ['Travel']
        },
        {
          title: 'Black Cloud',
          location: 'Amsterdam, Netherlands',
          date: new Date('2019/12/22 14:54'),
          description: `<p>Black Cloud, Carlos Amorales (2007)</p>
            <p>A swarm of black paper moths in different sizes occupying the entire walls and ceilings of four galleries of the Stedelijk Museum Amsterdam. Each moth was cut with a laser, folded by hand and then glued onto surfaces.</p>`,
          url: '/images/black_cloud.jpg',
          flag: 'nl',
          tags: ['Travel', 'Art']
        },
        {
          title: 'Brandenburger Tor',
          location: 'Berlin, Germany',
          date: new Date('2020/03/07 17:17'),
          description: '<p>The Brandenburger Tor (Brandenburg Gate) is an 18th-century neoclassical monument in Berlin, built on the orders of Prussian king Frederick William II after the temporary restoration of order during the Batavian Revolution. One of the best-known landmarks of Germany, it was built on the site of a former city gate that marked the start of the road from Berlin to the town of Brandenburg an der Havel, which used to be capital of the Margraviate of Brandenburg.</p>',
          url: '/images/bradenburger_tor.jpg',
          flag: 'de',
          tags: ['Travel']
        },
        {
          title: 'Charles Bridge',
          location: 'Prague, Czech Republic',
          date: new Date('2020/01/18 16:38'),
          description: '<p>Charles Bridge is a historic bridge that crosses the Vltava (Moldau) river in Prague, Czech Republic. Its construction started in 1357 under the auspices of King Charles IV, and finished in the beginning of the 15th century. As the only means of crossing the river Vltava until 1841, Charles Bridge was the most important connection between Prague Castle and the city\'s Old Town and adjacent areas.</p>',
          url: '/images/charles_bridge.jpg',
          flag: 'cz',
          tags: ['Travel']
        },
        {
          title: 'Charles Bridge',
          location: 'Prague, Czech Republic',
          date: new Date('2020/01/19 13:34'),
          description: '<p>Charles Bridge is a historic bridge that crosses the Vltava (Moldau) river in Prague, Czech Republic. Its construction started in 1357 under the auspices of King Charles IV, and finished in the beginning of the 15th century. As the only means of crossing the river Vltava until 1841, Charles Bridge was the most important connection between Prague Castle and the city\'s Old Town and adjacent areas.</p>',
          url: '/images/charles_bridge_2.jpg',
          flag: 'cz',
          tags: ['Travel']
        },
        {
          title: 'Church of our Lady Before Tyn',
          location: 'Prague, Czech Republic',
          date: new Date('2020/01/18 17:49'),
          description: 'The Church of Mother of God before Týn (Týn Church), often translated as Church of Our Lady before Týn, is a Gothic church and a dominant feature of the Old Town of Prague, Czech Republic. It has been the main church of this part of the city since the 14th century. The church\'s two towers are 80 m high, and each tower\'s spire is topped by eight smaller spires in two layers of four.</p>',
          url: '/images/church_of_our_lady_before_tyn.jpg',
          flag: 'cz',
          tags: ['Travel']
        },
        {
          title: 'Dancing House',
          location: 'Prague, Czech Republic',
          date: new Date('2020/01/19 10:38'),
          description: '<p>The Dancing House, or Fred and Ginger, is the nickname given to the Nationale-Nederlanden building on the Rašínovo nábřeží (Rašín Embankment) in Prague, Czech Republic. It was designed by the Croatian-Czech architect Vlado Milunić in cooperation with Canadian-American architect Frank Gehry on a vacant riverfront plot. The building was designed in 1992 and was completed four years later in 1996.</p>',
          url: '/images/dancing_house.jpg',
          flag: 'cz',
          tags: ['Travel']
        },
        {
          title: 'Diagonal Solution to a Problem',
          location: 'Berlin, Germany',
          date: new Date('2020/03/08 10:27'),
          description: '<p>The simple gesture of a ‘thumbs up’ is usually interpreted as everything being ok. For 28 years in Berlin, everything was far from being all right. However the opinions and emotions felt by the citizens of the city held little value to those who were in charge of the oppression.</p>',
          url: '/images/diagonal_solution_to_a_problem.jpg',
          flag: 'de',
          tags: ['Travel', 'Art']
        },
        {
          title: 'Edicola Bocconi',
          location: 'Milan, Italy',
          date: new Date('2019/09/06 12:50'),
          description: '<p>The funeral monument was erected by the will of Ferdinando Bocconi (1836-1908), an important Milanese industrialist and merchant, who opened the "Alle città d\'Italia" department stores in the Lombard capital, in a splendid location overlooking Piazza Duomo. (corresponding to today\'s "Rinascente"). Then in Milan, in 1902, he founded the “Luigi Bocconi” Commercial University, in memory of his son who died at the age of twenty-seven on the African continent.</p>',
          url: '/images/edicola_bocconi.jpg',
          flag: 'it',
          tags: ['Travel', 'Art']
        },
        {
          title: 'Eiffel Tower',
          location: 'Paris, France',
          date: new Date('2019/06/23 16:04'),
          description: `<p>The Eiffel Tower is a wrought-iron lattice tower on the Champ de Mars in Paris, France. It is named after the engineer Gustave Eiffel, whose company designed and built the tower.</p>
            <p>Constructed from 1887 to 1889 as the entrance to the 1889 World's Fair, it was initially criticised by some of France's leading artists and intellectuals for its design, but it has become a global cultural icon of France and one of the most recognisable structures in the world. The Eiffel Tower is the most-visited paid monument in the world; 6.91 million people ascended it in 2015.</p>`,
          url: '/images/effiel_tower.jpg',
          flag: 'fr',
          tags: ['Travel']
        },
        {
          title: 'Estacao de Oriente',
          location: 'Lisbon, Portugal',
          date: new Date('2019/11/10 19:01'),
          description: '<p>Gare do Oriente, or alternately, the Lisbon Oriente Station is one of the main Portuguese intermodal transport hubs, and is situated in the civil parish of Parque das Nações, municipality of Lisbon.</p>',
          url: '/images/estacao_de_oriente.jpg',
          flag: 'pt',
          tags: ['Travel', 'Art']
        },
        {
          title: 'Estadio Da Luz',
          location: 'Lisbon, Portugal',
          date: new Date('2019/11/09 17:53'),
          description: '<p>The Estádio da Luz, officially named Estádio do Sport Lisboa e Benfica, is a multi-purpose stadium located in Lisbon, Portugal. It is used mostly for association football matches, hosting the home games of Portuguese club S.L. Benfica, its owner.</p>',
          url: '/images/estadio_da_luz.jpg',
          flag: 'pt',
          tags: ['Travel']
        },
        {
          title: 'Freetown Christiania',
          location: 'Copenhagen, Denmark',
          date: new Date('2019/10/07 20:24'),
          description: '<p>Freetown Christiania, also known as Christiania (Danish: Fristaden Christiania or Staden), is an intentional community and commune of about 850 to 1,000 residents, covering 7.7 hectares (19 acres) in the borough of Christianshavn in the Danish capital city of Copenhagen.</p>',
          url: '/images/freetown_christiania.jpg',
          flag: 'dk',
          tags: ['Travel']
        },
        {
          title: 'Globe',
          location: 'Berlin, Germany',
          date: new Date('2020/03/08 10:25'),
          description: '<p>Globe – East Side Gallery in Berlin.</p>',
          url: '/images/globe.jpg',
          flag: 'de',
          tags: ['Travel', 'Art']
        },
        {
          title: 'Igreja Santa Maria de Belem',
          location: 'Lisbon, Portugal',
          date: new Date('2019/11/10 15:18'),
          description: '<p>The Jerónimos Monastery or Hieronymites Monastery, is a former monastery of the Order of Saint Jerome near the Tagus river in the parish of Belém, in the Lisbon Municipality, Portugal; it was secularised on 28 December 1833 by state decree and its ownership transferred to the charitable institution, Real Casa Pia de Lisboa.</p>',
          url: '/images/igreja_santa_maria_de_belem.jpg',
          flag: 'pt',
          tags: ['Travel']
        },
        {
          title: 'Lennon Wall',
          location: 'Prague, Czech Republic',
          date: new Date('2020/01/19 13:23'),
          description: '<p>The Lennon Wall or John Lennon Wall is a wall in Prague, Czechia. Since the 1980s this once typical wall has been filled with John Lennon-inspired graffiti, lyrics from Beatles\' songs, and designs relating to local and global causes.</p>',
          url: '/images/lennon_wall.jpg',
          flag: 'cz',
          tags: ['Travel', 'Art']
        },
        {
          title: 'Lisbon Street',
          location: 'Lisbon, Portugal',
          date: new Date('2019/11/10 02:32'),
          description: '',
          url: '/images/lisbon_street.jpg',
          flag: 'pt',
          tags: ['Travel']
        },
        {
          title: 'The Little Mermaid',
          location: 'Copenhagen, Denmark',
          date: new Date('2019/10/08 11:00'),
          description: `<p>The Little Mermaid is a bronze statue by Edvard Eriksen, depicting a mermaid becoming human. The sculpture is displayed on a rock by the waterside at the Langelinie promenade in Copenhagen, Denmark.</p>
            <p>Based on the 1837 fairy tale of the same name by Danish author Hans Christian Andersen, the small and unimposing statue is a Copenhagen icon and has been a major tourist attraction since its unveiling in 1913.</p>`,
          url: '/images/little_mermaid.jpg',
          flag: 'dk',
          tags: ['Travel', 'Art']
        },
        {
          title: 'Marques de Pombal',
          location: 'Lisbon, Portugal',
          date: new Date('2019/11/09 17:19'),
          description: '<p>The Marquis of Pombal Square is an important roundabout in the city of Lisbon, Portugal. It is located between the Avenida da Liberdade (Liberty Avenue) and the Eduardo VII Park in the former parish of Coração de Jesus and in the quarter of Santo António.</p>',
          url: '/images/marques_de_pombal.jpg',
          flag: 'pt',
          tags: ['Travel']
        },
        {
          title: 'Memorial to the Murdered Jews',
          location: 'Berlin, Germany',
          date: new Date('2020/03/07 17:29'),
          description: '<p>The Memorial to the Murdered Jews of Europe, also known as the Holocaust Memorial, is a memorial in Berlin to the Jewish victims of the Holocaust, designed by architect Peter Eisenman and engineer Buro Happold. It consists of 2,711 concrete slabs or "stelae", arranged in a grid pattern on a sloping field.</p>',
          url: '/images/memorial_to_the_murdered_jews.jpg',
          flag: 'de',
          tags: ['Featured', 'Travel']
        },
        {
          title: 'Memorial to the Victims of National Socialism',
          location: 'Berlin, Germany',
          date: new Date('2020/03/07 16:44'),
          description: '<p>The Memorial and Information Point for the Victims of National Socialist Euthanasia Killings is a memorial to the people euthanized by the National Socialist Government, located in Berlin, Germany. From 1940 to 1941 over 70,000 people were murdered under the Action T4 plan. After the Third Reich ended the program, the killings continued in Nazi occupied institutions and care facilities until the end of World War II in 1945. This amounted to a death toll of approximately 300,000.</p>',
          url: '/images/memorial_to_the_victims_of_national_socialism.jpg',
          flag: 'de',
          tags: ['Travel']
        },
        {
          title: 'Mlyn Hut',
          location: 'Prague, Czech Republic',
          date: new Date('2020/01/19 13:19'),
          description: '',
          url: '/images/mlyn_hut.jpg',
          flag: 'cz',
          tags: ['Travel']
        },
        {
          title: 'Monolith Eugene',
          location: 'Oslo, Norway',
          date: new Date('2019/11/03 12:34'),
          description: '<p>The Monolith Plateau is a platform in the north of Frogner Park made of steps that houses the Monolith totem itself. 36 figure groups reside on the elevation, representing a “circle of life” theme. Access to the Plateau is via eight wrought iron gates depicting human figures. The gates were designed between 1933 and 1937 and erected shortly after Vigeland died in 1943.</p>',
          url: '/images/monolith_eugene.jpg',
          flag: 'no',
          tags: ['Travel', 'Art']
        },
        {
          title: 'My God, Help Me to Survive this Deadly Love',
          location: 'Berlin, Germany',
          date: new Date('2020/03/08 10:26'),
          description: '<p>My God, Help Me to Survive This Fatal Attraction, sometimes referred to as the Fraternal Kiss, is a graffiti painting by Dmitri Vrubel on the eastern side Berlin wall. Painted in 1990, it depicts Leonid Brezhnev and Erich Honecker in a socialist fraternal kiss.</p>',
          url: '/images/my_god_help_me_to_survive_this_deadly_love.jpg',
          flag: 'de',
          tags: ['Travel', 'Art']
        },
        {
          title: 'Oslo Opera House',
          location: 'Oslo, Norway',
          date: new Date('2019/11/02 16:25'),
          description: '<p>The Oslo Opera House is the home of the Norwegian National Opera and Ballet, and the national opera theatre in Norway. The angled exterior surfaces of the building are covered with marble from Carrara, Italy and white granite and make it appear to rise from the water. It is the largest cultural building constructed in Norway since Nidarosdomen was completed circa 1300.</p>',
          url: '/images/opera_house.jpg',
          flag: 'no',
          tags: ['Travel']
        },
        {
          title: 'Our Hands',
          location: 'Copenhagen, Denmark',
          date: new Date('2019/10/08 18:00'),
          description: '',
          url: '/images/our_hands.jpg',
          flag: 'dk',
          tags: ['Travel', 'Art']
        },
        {
          title: 'Panteao Nacional',
          location: 'Lisbon, Portugal',
          date: new Date('2019/11/10 12:51'),
          description: '<p>The Church of Santa Engrácia is a 17th-century monument in Lisbon, Portugal. Originally a church, in the 20th century it was converted into the National Pantheon, in which important Portuguese personalities are buried. It is located in the Alfama neighborhood, close to another important Lisbon monument, the Monastery of São Vicente de Fora.</p>',
          url: '/images/panteao_nacional.jpg',
          flag: 'pt',
          tags: ['Travel']
        },
        {
          title: 'Panthéon',
          location: 'Paris, France',
          date: new Date('2019/06/23 18:45'),
          description: '<p>The Panthéon (temple to all the gods) is a monument in the 5th arrondissement of Paris, France. It is located in the area known as the Latin Quarter, standing atop the Montagne Sainte-Geneviève, at the center of the Place du Panthéon which was named after it.</p>',
          url: '/images/pantheon.jpg',
          flag: 'fr',
          tags: ['Travel']
        },
        {
          title: 'Parque de el Retiro',
          location: 'Madrid, Spain',
          date: new Date('2019/06/22 15:36'),
          description: '<p>The Buen Retiro Park (Park of the Pleasant Retreat), Retiro Park or simply El Retiro is one of the largest parks of the city of Madrid, Spain. The park belonged to the Spanish Monarchy until the late 19th century, when it became a public park.</p>',
          url: '/images/parque_de_el_retiro.jpg',
          flag: 'es',
          tags: ['Travel', 'Nature']
        },
        {
          title: 'Parque de el Retiro',
          location: 'Madrid, Spain',
          date: new Date('2019/06/22 15:36'),
          description: '<p>The Buen Retiro Park (Park of the Pleasant Retreat), Retiro Park or simply El Retiro is one of the largest parks of the city of Madrid, Spain. The park belonged to the Spanish Monarchy until the late 19th century, when it became a public park.</p>',
          url: '/images/parque_de_el_retiro_2.jpg',
          flag: 'es',
          tags: ['Travel', 'Nature']
        },
        {
          title: 'Parque de el Retiro',
          location: 'Madrid, Spain',
          date: new Date('2019/06/22 15:37'),
          description: '<p>The Buen Retiro Park (Park of the Pleasant Retreat), Retiro Park or simply El Retiro is one of the largest parks of the city of Madrid, Spain. The park belonged to the Spanish Monarchy until the late 19th century, when it became a public park.</p>',
          url: '/images/parque_de_el_retiro_3.jpg',
          flag: 'es',
          tags: ['Travel', 'Nature']
        },
        {
          title: 'Parque de el Retiro',
          location: 'Madrid, Spain',
          date: new Date('2019/06/22 15:39'),
          description: '<p>The Buen Retiro Park (Park of the Pleasant Retreat), Retiro Park or simply El Retiro is one of the largest parks of the city of Madrid, Spain. The park belonged to the Spanish Monarchy until the late 19th century, when it became a public park.</p>',
          url: '/images/parque_de_el_retiro_4.jpg',
          flag: 'es',
          tags: ['Travel', 'Nature']
        },
        {
          title: 'Parque de el Retiro',
          location: 'Madrid, Spain',
          date: new Date('2019/06/22 15:41'),
          description: '<p>The Buen Retiro Park (Park of the Pleasant Retreat), Retiro Park or simply El Retiro is one of the largest parks of the city of Madrid, Spain. The park belonged to the Spanish Monarchy until the late 19th century, when it became a public park.</p>',
          url: '/images/parque_de_el_retiro_5.jpg',
          flag: 'es',
          tags: ['Travel', 'Nature']
        },
        {
          title: 'Parque Eduardo VII',
          location: 'Lisbon, Portugal',
          date: new Date('2019/11/09 17:10'),
          description: '<p>Eduardo VII Park is a public park in Lisbon, Portugal. The park occupies an area of 26 hectares (64 acres) to the north of Avenida da Liberdade and Marquis of Pombal Square in Lisbon\'s city center.</p>',
          url: '/images/parque_eduardo_7.jpg',
          flag: 'pt',
          tags: ['Travel', 'Nature']
        },
        {
          title: 'Penguins',
          location: 'Oslo, Norway',
          date: new Date('2019/11/03 14:55'),
          description: '',
          url: '/images/penguins.jpg',
          flag: 'no',
          tags: ['Travel', 'Nature']
        },
        {
          title: 'Potsdamer Platz',
          location: 'Berlin, Germany',
          date: new Date('2020/03/07 17:42'),
          description: '<p>Potsdamer Platz, (Potsdam Square) is an important public square and traffic intersection in the center of Berlin, Germany. It is named after the city of Potsdam, and marks the point where the old road from Potsdam passed through the city wall of Berlin at the Potsdam Gate.</p>',
          url: '/images/potsdamer_platz.jpg',
          flag: 'de',
          tags: ['Featured', 'Travel']
        },
        {
          title: 'Praca do Rossio',
          location: 'Lisbon, Portugal',
          date: new Date('2019/11/09 18:59'),
          description: '<p>The Rossio is the popular name of the King Pedro IV Square in the city of Lisbon, in Portugal. It is located in the Pombaline Downtown of Lisbon and has been one of its main squares since the Middle Ages. It has been the setting of popular revolts and celebrations, bullfights and executions, and is now a preferred meeting place of Lisbon natives and tourists alike.</p>',
          url: '/images/praca_do_rossio.jpg',
          flag: 'pt',
          tags: ['Travel']
        },
        {
          title: 'Reichstag',
          location: 'Berlin, Germany',
          date: new Date('2020/03/08 16:22'),
          description: '<p>The Reichstag is a historic edifice in Berlin, Germany, constructed to house the Imperial Diet (Reichstag) of the German Empire. It was opened in 1894 and housed the Diet until 1933, when it was severely damaged after being set on fire. After World War II, the building fell into disuse.</p>',
          url: '/images/reichstag.jpg',
          flag: 'de',
          tags: ['Travel']
        },
        {
          title: 'Reichstag',
          location: 'Berlin, Germany',
          date: new Date('2020/03/08 17:27'),
          description: '<p>The Reichstag is a historic edifice in Berlin, Germany, constructed to house the Imperial Diet (Reichstag) of the German Empire. It was opened in 1894 and housed the Diet until 1933, when it was severely damaged after being set on fire. After World War II, the building fell into disuse.</p>',
          url: '/images/reichstag_2.jpg',
          flag: 'de',
          tags: ['Travel']
        },
        {
          title: 'Rossio Station',
          location: 'Lisbon, Portugal',
          date: new Date('2019/11/09 18:23'),
          description: '<p>The Rossio Railway Station is a railway station in Lisbon, Portugal, located in the Rossio square. The station was formerly known as Estação Central (Central Station) and that designation still appears in its façade. Trains gain access to the station, which is in the central urban area of Lisbon, through a tunnel which is over 2.9 km (1.8 mi) long.</p>',
          url: '/images/rossio_station.jpg',
          flag: 'pt',
          tags: ['Travel']
        },
        {
          title: 'Royal Palace',
          location: 'Oslo, Norway',
          date: new Date('2019/11/03 11:28'),
          description: '<p>The Royal Palace in Oslo was built in the first half of the 19th century as the Norwegian residence of the French-born King Charles III John of Norway, who reigned as king of Norway and Sweden. The palace is the official residence of the current Norwegian monarch while the Crown Prince resides at Skaugum in Asker west of Oslo.</p>',
          url: '/images/royal_palace.jpg',
          flag: 'no',
          tags: ['Travel']
        },
        {
          title: 'Gare do Oriente',
          location: 'Lisbon, Portugal',
          date: new Date('2019/11/10 18:46'),
          description: '<p>The Gare do Oriente, also known as Gare Intermodal de Lisboa, or Lisbon Train Station - East, or Lisbon Middle Concentration Station, it is one of the train and bus interfaces most important in Lisbon, Portugal. Designed by Spanish architect and engineer Santiago Calatrava, it was completed in 1998 to serve Expo\'98, and later Parque das Nações.</p>',
          url: '/images/sailor.jpg',
          flag: 'pt',
          tags: ['Travel']
        },
        {
          title: 'Santuario de Cristo Rei',
          location: 'Almada, Portugal',
          date: new Date('2019/11/09 20:36'),
          description: 'The Sanctuary of Christ the King is a Catholic monument and shrine dedicated to the Sacred Heart of Jesus Christ overlooking the city of Lisbon situated in Almada, in Portugal. It was inspired by the Christ the Redeemer statue of Rio de Janeiro, in Brazil. The giant statue was erected to express gratitude because the Portuguese were spared the effects of World War II.</p>',
          url: '/images/santuario_de_cristo_rei.jpg',
          flag: 'pt',
          tags: ['Travel']
        },
        {
          title: 'Sao Jorge Castle',
          location: 'Lisbon, Portugal',
          date: new Date('2019/11/10 12:23'),
          description: '<p>São Jorge Castle is a historic castle in the Portuguese capital of Lisbon, located in the freguesia of Santa Maria Maior. Human occupation of the castle hill dates to at least the 8th century BC. Since the 12th century, the castle has variously served as a royal palace, a military barracks, home of the Torre do Tombo National Archive, and now as a national monument and museum.</p>',
          url: '/images/sao_jorge_castle.jpg',
          flag: 'pt',
          tags: ['Featured', 'Travel']
        },
        {
          title: 'Seagull',
          location: 'Oslo, Norway',
          date: new Date('2019/11/02 16:39'),
          description: '',
          url: '/images/seagull.jpg',
          flag: 'no',
          tags: ['Travel', 'Nature']
        },
        {
          title: 'Spreepark',
          location: 'Berlin, Germany',
          date: new Date('2020/03/08 15:12'),
          description: '<p>Spreepark is an abandoned amusement park in the north of the Plänterwald in the Berlin district Treptow-Köpenick (formerly part of the GDR-controlled East Berlin). It was also known by its earlier name Kulturpark Plänterwald Berlin.</p>',
          url: '/images/spreepark.jpg',
          flag: 'de',
          tags: ['Travel']
        },
        {
          title: 'Stary Zidovsky Hrbitov',
          location: 'Prague, Czech Republic',
          date: new Date('2020/01/19 13:59'),
          description: '<p>The Old Jewish Cemetery is a Jewish cemetery in Prague, Czech Republic, which is one of the largest of its kind in Europe and one of the most important Jewish historical monuments in Prague. Today the cemetery is administered by the Jewish Museum in Prague.</p>',
          url: '/images/stary_zidovsky_hrbitov.jpg',
          flag: 'cz',
          tags: ['Travel']
        },
        {
          title: 'Berlin Television Tower',
          location: 'Berlin, Germany',
          date: new Date('2020/03/07 18:53'),
          description: `<p>The Berliner Fernsehturm or Fernsehturm Berlin (Berlin Television Tower) is a television tower in central Berlin, Germany.</p>
            <p>Close to Alexanderplatz in the locality and in the district of Mitte, the tower was constructed between 1965 and 1969 by the government of the German Democratic Republic (East Germany). It was intended to be both a symbol of Communist power and of the city. It remains a landmark today, visible throughout the central and some suburban districts of Berlin. With its height of 368 metres (including antenna) it is the tallest structure in Germany, and the third-tallest structure in the European Union.</p>`,
          url: '/images/television_tower.jpg',
          flag: 'de',
          tags: ['Travel']
        },
        {
          title: 'The King\'s Gardens',
          location: 'Copenhagen, Denmark',
          date: new Date('2019/10/08 14:33'),
          description: '<p>Rosenborg Castle Gardens is the oldest and most visited park in central Copenhagen, Denmark. Established in the early 17th century as the private gardens of King Christian IV\'s Rosenborg Castle, the park also contains several other historical buildings, including Rosenborg Barracks, home to the Royal Guards, as well as a high number of statues and monuments.</p>',
          url: '/images/the_kings_gardens.jpg',
          flag: 'dk',
          tags: ['Travel', 'Nature']
        },
        {
          title: 'The Church of Our Saviour',
          location: 'Copenhagen, Denmark',
          date: new Date('2019/10/08 17:40'),
          description: '<p>The Church of Our Saviour is a baroque church in Copenhagen, Denmark, most famous for its helix spire with an external winding staircase that can be climbed to the top, offering extensive views over central Copenhagen. It is also noted for its carillon, which is the largest in northern Europe and plays melodies every hour from 8 am to midnight.</p>',
          url: '/images/view_from_church.jpg',
          flag: 'dk',
          tags: ['Travel']
        },
        {
          title: 'Oslo Opera House',
          location: 'Oslo, Norway',
          date: new Date('2019/11/02 16:40'),
          description: '<p>The Oslo Opera House is the home of the Norwegian National Opera and Ballet, and the national opera theatre in Norway. The angled exterior surfaces of the building are covered with marble from Carrara, Italy and white granite and make it appear to rise from the water. It is the largest cultural building constructed in Norway since Nidarosdomen was completed circa 1300.</p>',
          url: '/images/view_from_opera_house.jpg',
          flag: 'no',
          tags: ['Travel']
        },
        {
          title: 'Prague Castle',
          location: 'Prague, Czech Republic',
          date: new Date('2020/01/18 14:16'),
          description: '<p>Prague Castle is a castle complex in Prague, Czech Republic, built in the 9th century. It is the official office of the President of the Czech Republic. The castle was a seat of power for kings of Bohemia, Holy Roman emperors, and presidents of Czechoslovakia. The Bohemian Crown Jewels are kept within a hidden room inside it.</p>',
          url: '/images/view_from_prague_castle.jpg',
          flag: 'cz',
          tags: ['Travel']
        },
        {
          title: 'View of Lisbon',
          location: 'Almada, Portugal',
          date: new Date('2019/11/09 20:41'),
          description: '',
          url: '/images/view_of_lisbon.jpg',
          flag: 'pt',
          tags: ['Travel']
        },
        {
          title: 'View of Lisbon',
          location: 'Almada, Portugal',
          date: new Date('2019/11/09 20:53'),
          description: '',
          url: '/images/view_of_lisbon_2.jpg',
          flag: 'pt',
          tags: ['Travel']
        },
        {
          title: 'Zlata Ulicka',
          location: 'Prague, Czech Republic',
          date: new Date('2020/01/18 14:00'),
          description: '<p>Golden Lane (Zlatá ulička) is a street situated in Prague Castle, Czech Republic. Originally built in the 16th century to house Rudolf II\'s castle guards, it takes its name from the goldsmiths that lived there in the 17th century. Although the lane was temporarily called the Street of Alchemists or Alchemists\' Alley, alchemists have never worked or lived there.</p>',
          url: '/images/zlata_ulicka.jpg',
          flag: 'cz',
          tags: ['Travel']
        },


        {
          title: 'Albert Memorial',
          location: 'London, England',
          date: new Date('2020/10/15 15:04'),
          description: '',
          url: '/images/albert_memorial.jpg',
          flag: 'gb-eng',
          tags: []
        },
        // {
        //   title: 'Kensington Gardens',
        //   location: 'London, England',
        //   date: new Date('2020/10/15 15:30'),
        //   description: '',
        //   url: '/images/birds_1.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        // {
        //   title: 'Kensington Gardens',
        //   location: 'London, England',
        //   date: new Date('2020/10/15 15:30'),
        //   description: '',
        //   url: '/images/birds_2.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        {
          title: 'Kensington Gardens',
          location: 'London, England',
          date: new Date('2020/10/15 15:30'),
          description: '',
          url: '/images/birds_3.jpg',
          flag: 'gb-eng',
          tags: ['Nature']
        },
        {
          title: 'Kensington Gardens',
          location: 'London, England',
          date: new Date('2020/10/15 15:30'),
          description: '',
          url: '/images/birds_4.jpg',
          flag: 'gb-eng',
          tags: ['Nature']
        },
        {
          title: 'Kensington Gardens',
          location: 'London, England',
          date: new Date('2020/10/15 15:30'),
          description: '',
          url: '/images/birds_5.jpg',
          flag: 'gb-eng',
          tags: ['Nature']
        },
        {
          title: 'Buckingham Palace',
          location: 'London, England',
          date: new Date('2020/10/08 18:35'),
          description: '',
          url: '/images/buckingham_palace_1.jpg',
          flag: 'gb-eng',
          tags: []
        },
        {
          title: 'Buckingham Palace',
          location: 'London, England',
          date: new Date('2020/10/08 18:38'),
          description: '',
          url: '/images/buckingham_palace_2.jpg',
          flag: 'gb-eng',
          tags: []
        },
        {
          title: 'Cemetery',
          location: 'London, England',
          date: new Date('2020/10/01 02:19'),
          description: '',
          url: '/images/cemetery.jpg',
          flag: 'gb-eng',
          tags: []
        },
        {
          title: 'Chinatown',
          location: 'London, England',
          date: new Date('2020/10/19 15:27'),
          description: '',
          url: '/images/chinatown.jpg',
          flag: 'gb-eng',
          tags: []
        },
        {
          title: 'Chinatown Gate',
          location: 'London, England',
          date: new Date('2020/10/19 15:23'),
          description: '',
          url: '/images/chinatown_gate_1.jpg',
          flag: 'gb-eng',
          tags: []
        },
        // {
        //   title: 'Chinatown Gate 2',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/chinatown_gate_2.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        {
          title: 'Chinatown Gate',
          location: 'London, England',
          date: new Date('2020/10/19 15:23'),
          description: '',
          url: '/images/chinatown_gate_3.jpg',
          flag: 'gb-eng',
          tags: []
        },
        {
          title: 'Christchurch Greyfriars',
          location: 'London, England',
          date: new Date('2020/10/10 13:23'),
          description: '',
          url: '/images/christchurch_greyfriars.jpg',
          flag: 'gb-eng',
          tags: ['Nature']
        },
        {
          title: 'Clouds',
          location: 'Lisbon, Portugal',
          date: new Date('2019/11/10 13:10'),
          description: '',
          url: '/images/clouds.jpg',
          flag: 'pt',
          tags: ['Travel']
        },
        {
          title: 'Duck Island Cottage',
          location: 'London, England',
          date: new Date('2020/10/08 18:22'),
          description: '',
          url: '/images/duck_island_cottage_1.jpg',
          flag: 'gb-eng',
          tags: ['Nature']
        },
        // {
        //   title: 'Duck Island Cottage',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/duck_island_cottage_2.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        {
          title: 'Ducks',
          location: 'Dorking, England',
          date: new Date('2020/06/14 14:43'),
          description: '',
          url: '/images/ducks.jpg',
          flag: 'gb-eng',
          tags: ['Nature']
        },
        {
          title: 'Duomo di Milano',
          location: 'Milan, Italy',
          date: new Date('2019/10/05 13:00'),
          description: '',
          url: '/images/duomo_di_milano_2.jpg',
          flag: 'it',
          tags: ['Travel']
        },
        {
          title: 'Eiffel Tower',
          location: 'Paris, France',
          date: new Date('2019/06/23 12:51'),
          description: '',
          url: '/images/eiffel_tower_2.jpg',
          flag: 'fr',
          tags: ['Travel']
        },
        {
          title: 'Yellow Flowers',
          location: 'London, England',
          date: new Date('2020/10/10 13:15'),
          description: '',
          url: '/images/flowers.jpg',
          flag: 'gb-eng',
          tags: ['Nature']
        },
        {
          title: 'Flower Camera',
          location: 'London, England',
          date: new Date('2020/10/10 11:31'),
          description: '',
          url: '/images/flower_camera.jpg',
          flag: 'gb-eng',
          tags: ['Art']
        },
        {
          title: 'Frogner Park',
          location: 'Oslo, Norway',
          date: new Date('2019/11/03 12:27'),
          description: '',
          url: '/images/frogner_park_1.jpg',
          flag: 'no',
          tags: ['Travel', 'Art']
        },
        {
          title: 'Frogner Park',
          location: 'Oslo, Norway',
          date: new Date('2019/11/03 12:27'),
          description: '',
          url: '/images/frogner_park_2.jpg',
          flag: 'no',
          tags: ['Travel', 'Art']
        },
        {
          title: 'Frogner Park',
          location: 'Oslo, Norway',
          date: new Date('2019/11/03 12:28'),
          description: '',
          url: '/images/frogner_park_3.jpg',
          flag: 'no',
          tags: ['Travel', 'Art']
        },
        {
          title: 'Frogner Park',
          location: 'Oslo, Norway',
          date: new Date('2019/11/03 12:33'),
          description: '',
          url: '/images/frogner_park_4.jpg',
          flag: 'no',
          tags: ['Travel', 'Art']
        },
        {
          title: 'Gift-wrapped Shop',
          location: 'Amsterdam, Netherlands',
          date: new Date('2019/12/21 09:46'),
          description: '',
          url: '/images/gift_wrapped_shop.jpg',
          flag: 'nl',
          tags: ['Travel']
        },
        {
          title: 'Girl with a Dolphin',
          location: 'London, England',
          date: new Date('2020/10/10 14:38'),
          description: '',
          url: '/images/girl_with_a_dolphin_fountain_1.jpg',
          flag: 'gb-eng',
          tags: ['Art']
        },
        // {
        //   title: 'Girl with a Dolphin',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/girl_with_a_dolphin_fountain_2.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        // {
        //   title: 'Girl with a Dolphin',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/girl_with_a_dolphin_fountain_3.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        {
          title: 'The Good Samaritan',
          location: 'Paris, France',
          date: new Date('2019/06/23 11:27'),
          description: '',
          url: '/images/good_samaritan.jpg',
          flag: 'fr',
          tags: ['Travel', 'Art']
        },
        {
          title: 'Jerusalem Synagogue',
          location: 'Prague, Czech Republic',
          date: new Date('2020/01/19 15:02'),
          description: '',
          url: '/images/jerusalem_synagogue.jpg',
          flag: 'cz',
          tags: ['Travel']
        },
        // {
        //   title: 'Karl Johans Gate',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/karl_johans_gate.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        {
          title: 'Karl Johans Gate',
          location: 'Oslo, Norway',
          date: new Date('2019/11/03 11:16'),
          description: '',
          url: '/images/karl_johans_gate_2.jpg',
          flag: 'no',
          tags: ['Travel']
        },
        // {
        //   title: 'Kensington Gardens',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/kensington_garden_1.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        // {
        //   title: 'Kensington Gardens',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/kensington_garden_2.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        // {
        //   title: 'Leadenhall Market',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/leadenhall_market_1.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        {
          title: 'Leadenhall Market',
          location: 'London, England',
          date: new Date('2020/10/18 18:28'),
          description: '',
          url: '/images/leadenhall_market_2.jpg',
          flag: 'gb-eng',
          tags: ['Featured']
        },
        {
          title: 'Leake Street',
          location: 'London, England',
          date: new Date('2020/10/08 17:45'),
          description: '',
          url: '/images/leake_street_1.jpg',
          flag: 'gb-eng',
          tags: ['Art']
        },
        {
          title: 'Leake Street',
          location: 'London, England',
          date: new Date('2020/10/08 17:47'),
          description: '',
          url: '/images/leake_street_2.jpg',
          flag: 'gb-eng',
          tags: ['Art']
        },
        {
          title: 'Little Venice',
          location: 'London, England',
          date: new Date('2020/10/15 16:14'),
          description: '',
          url: '/images/little_venice_1.jpg',
          flag: 'gb-eng',
          tags: ['Nature']
        },
        // {
        //   title: 'Little Venice',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/little_venice_2.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        {
          title: 'Little Venice',
          location: 'London, England',
          date: new Date('2020/10/15 16:25'),
          description: '',
          url: '/images/little_venice_3.jpg',
          flag: 'gb-eng',
          tags: ['Nature']
        },
        {
          title: 'London Eye',
          location: 'London, England',
          date: new Date('2020/10/08 17:07'),
          description: '',
          url: '/images/london_eye.jpg',
          flag: 'gb-eng',
          tags: []
        },
        {
          title: 'London Street',
          location: 'London, England',
          date: new Date('2020/10/10 14:10'),
          description: '',
          url: '/images/london_street_1.jpg',
          flag: 'gb-eng',
          tags: ['Featured']
        },
        // {
        //   title: 'London Street',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/london_street_2.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        // {
        //   title: 'London Street',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/london_street_3.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        {
          title: 'Marie de Medicis Fountain',
          location: 'Paris, France',
          date: new Date('2019/06/23 18:27'),
          description: '',
          url: '/images/marie_de_medicis_fountain.jpg',
          flag: 'fr',
          tags: ['Travel', 'Nature']
        },
        {
          title: 'Milano Centrale',
          location: 'Milan, Italy',
          date: new Date('2019/10/05 11:49'),
          description: '',
          url: '/images/milano_centrale.jpg',
          flag: 'it',
          tags: ['Travel']
        },
        {
          title: 'Museum of Cultural History',
          location: 'Oslo, Norway',
          date: new Date('2019/11/03 16:36'),
          description: '',
          url: '/images/museum_of_cultural_history.jpg',
          flag: 'no',
          tags: ['Travel']
        },
        {
          title: 'Narodni Muzeum',
          location: 'Prague, Czech Republic',
          date: new Date('2020/01/19 12:53'),
          description: '',
          url: '/images/narodni_muzeum.jpg',
          flag: 'cz',
          tags: ['Travel']
        },
        {
          title: 'National Gallery',
          location: 'London, England',
          date: new Date('2020/10/19 15:13'),
          description: '',
          url: '/images/national_gallery.jpg',
          flag: 'gb-eng',
          tags: []
        },
        {
          title: 'National Theatre',
          location: 'London, England',
          date: new Date('2020/10/10 11:35'),
          description: '',
          url: '/images/national_theatre.jpg',
          flag: 'gb-eng',
          tags: []
        },
        {
          title: 'Neal\'s Yard',
          location: 'London, England',
          date: new Date('2020/10/19 15:33'),
          description: '',
          url: '/images/neals_yard.jpg',
          flag: 'gb-eng',
          tags: []
        },
        {
          title: 'Owl',
          location: 'Cardiff, Wales',
          date: new Date('2020/01/03 15:34'),
          description: '',
          url: '/images/owl.jpg',
          flag: 'gb-wls',
          tags: ['Travel', 'Art']
        },
        // {
        //   title: 'Peter Pan',
        //   location: 'London, England',
        //   date: new Date('2020/10/15 15:28'),
        //   description: '',
        //   url: '/images/peter_pan_1.jpg',
        //   flag: 'gb-eng',
        //   tags: ['Art']
        // },
        {
          title: 'Peter Pan',
          location: 'London, England',
          date: new Date('2020/10/15 15:28'),
          description: '',
          url: '/images/peter_pan_2.jpg',
          flag: 'gb-eng',
          tags: ['Art']
        },
        {
          title: 'Portobello Road',
          location: 'London, England',
          date: new Date('2020/10/13 21:06'),
          description: '',
          url: '/images/portobello_road_1.jpg',
          flag: 'gb-eng',
          tags: []
        },
        // {
        //   title: 'Portobello Road',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/portobello_road_2.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        // {
        //   title: 'Portobello Road',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/portobello_road_3.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        // {
        //   title: 'Portobello Road',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/portobello_road_4.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        {
          title: 'Reichstag',
          location: 'Berlin, Germany',
          date: new Date('2020/03/08 17:18'),
          description: '',
          url: '/images/reichstag_3.jpg',
          flag: 'de',
          tags: ['Travel']
        },
        {
          title: 'Rijksmuseumtuinen',
          location: 'Amsterdam, Netherlands',
          date: new Date('2019/12/22 15:29'),
          description: '',
          url: '/images/rijksmuseumtuinen.jpg',
          flag: 'nl',
          tags: ['Travel', 'Nature']
        },
        // {
        //   title: 'Saint Dunstan in the East',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/saint_dunstan_in_the_east_1.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        {
          title: 'Saint Dunstan in the East',
          location: 'London, England',
          date: new Date('2020/10/10 14:15'),
          description: '',
          url: '/images/saint_dunstan_in_the_east_2.jpg',
          flag: 'gb-eng',
          tags: []
        },
        {
          title: 'Saint Dunstan in the East',
          location: 'London, England',
          date: new Date('2020/10/10 14:15'),
          description: '',
          url: '/images/saint_dunstan_in_the_east_3.jpg',
          flag: 'gb-eng',
          tags: []
        },
        // {
        //   title: 'Saint Dunstan in the East',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/saint_dunstan_in_the_east_4.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        // {
        //   title: 'Saint Dunstan in the East',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/saint_dunstan_in_the_east_5.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        // {
        //   title: '',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/saint_dunstan_in_the_east_6.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        {
          title: 'Saint Dunstan in the East',
          location: 'London, England',
          date: new Date('2020/10/10 14:17'),
          description: '',
          url: '/images/saint_dunstan_in_the_east_7.jpg',
          flag: 'gb-eng',
          tags: []
        },
        // {
        //   title: 'Saint Dunstan in the East',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/saint_dunstan_in_the_east_8.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        {
          title: 'Sky Garden',
          location: 'London, England',
          date: new Date('2020/10/10 14:18'),
          description: '',
          url: '/images/sky_garden.jpg',
          flag: 'gb-eng',
          tags: []
        },
        {
          title: 'Spreepark',
          location: 'Berlin, Germany',
          date: new Date('2020/03/08 15:08'),
          description: '',
          url: '/images/spreepark_2.jpg',
          flag: 'de',
          tags: ['Travel']
        },
        // {
        //   title: 'Squirrel',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/squirrel.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        {
          title: 'St James Park',
          location: 'London, England',
          date: new Date('2020/10/08 18:18'),
          description: '',
          url: '/images/st_james_park_1.jpg',
          flag: 'gb-eng',
          tags: ['Nature']
        },
        // {
        //   title: 'St James Park',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/st_james_park_2.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        {
          title: 'St Mungos',
          location: 'London, England',
          date: new Date('2020/10/15 15:58'),
          description: '',
          url: '/images/st_mungos.jpg',
          flag: 'gb-eng',
          tags: []
        },
        {
          title: 'St Paul\'s Cathedral',
          location: 'London, England',
          date: new Date('2020/10/10 13:07'),
          description: '',
          url: '/images/st_pauls_cathedral_1.jpg',
          flag: 'gb-eng',
          tags: []
        },
        // {
        //   title: 'St Paul\'s Cathedral',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/st_pauls_cathedral_2.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        {
          title: 'St Paul\'s Cathedral',
          location: 'London, England',
          date: new Date('2020/10/10 13:11'),
          description: '',
          url: '/images/st_pauls_cathedral_3.jpg',
          flag: 'gb-eng',
          tags: []
        },
        {
          title: 'St Paul\'s Cathedral',
          location: 'London, England',
          date: new Date('2020/10/10 13:18'),
          description: '',
          url: '/images/st_pauls_cathedral_4.jpg',
          flag: 'gb-eng',
          tags: ['Featured']
        },
        {
          title: 'Street Art',
          location: 'London, England',
          date: new Date('2020/10/18 16:27'),
          description: '',
          url: '/images/street_art_1.jpg',
          flag: 'gb-eng',
          tags: ['Art']
        },
        {
          title: 'Street Art',
          location: 'London, England',
          date: new Date('2020/10/18 16:31'),
          description: '',
          url: '/images/street_art_2.jpg',
          flag: 'gb-eng',
          tags: ['Art']
        },
        {
          title: 'Street Art',
          location: 'London, England',
          date: new Date('2020/10/18 16:45'),
          description: '',
          url: '/images/street_art_3.jpg',
          flag: 'gb-eng',
          tags: ['Art']
        },
        {
          title: 'Street Art',
          location: 'London, England',
          date: new Date('2020/10/18 16:49'),
          description: '',
          url: '/images/street_art_4.jpg',
          flag: 'gb-eng',
          tags: ['Art']
        },
        {
          title: 'Street Art',
          location: 'London, England',
          date: new Date('2020/10/18 18:02'),
          description: '',
          url: '/images/street_art_5.jpg',
          flag: 'gb-eng',
          tags: ['Art']
        },
        {
          title: 'Street Art',
          location: 'London, England',
          date: new Date('2020/10/18 18:02'),
          description: '',
          url: '/images/street_art_6.jpg',
          flag: 'gb-eng',
          tags: ['Art']
        },
        {
          title: 'Street Art',
          location: 'London, England',
          date: new Date('2020/10/18 17:05'),
          description: '',
          url: '/images/street_art_7.jpg',
          flag: 'gb-eng',
          tags: ['Art']
        },
        {
          title: 'Street Art',
          location: 'London, England',
          date: new Date('2020/10/10 13:00'),
          description: '',
          url: '/images/street_art_8.jpg',
          flag: 'gb-eng',
          tags: ['Art']
        },
        {
          title: 'Temple',
          location: 'London, England',
          date: new Date('2020/10/12 00:46'),
          description: '',
          url: '/images/temple_1.jpg',
          flag: 'gb-eng',
          tags: []
        },
        // {
        //   title: 'Temple',
        //   location: 'London, England',
        //   date: new Date('2020/10/12 00:46'),
        //   description: '',
        //   url: '/images/temple_2.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        {
          title: 'The Navigators',
          location: 'London, England',
          date: new Date('2020/10/10 15:18'),
          description: '',
          url: '/images/the_navigators.jpg',
          flag: 'gb-eng',
          tags: ['Art']
        },
        {
          title: 'Tower of London',
          location: 'London, England',
          date: new Date('2020/10/10 14:25'),
          description: '',
          url: '/images/tower_of_london.jpg',
          flag: 'gb-eng',
          tags: []
        },
        {
          title: 'Tower Bridge',
          location: 'London, England',
          date: new Date('2020/10/10 14:35'),
          description: '',
          url: '/images/tower_bridge_1.jpg',
          flag: 'gb-eng',
          tags: []
        },
        // {
        //   title: 'Tower Bridge',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/tower_bridge_2.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        // {
        //   title: 'Tower Bridge',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/tower_bridge_3.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        {
          title: 'Tower Bridge',
          location: 'London, England',
          date: new Date('2020/10/10 14:39'),
          description: '',
          url: '/images/tower_bridge_4.jpg',
          flag: 'gb-eng',
          tags: []
        },
        {
          title: 'Trafalgar Square',
          location: 'London, England',
          date: new Date('2020/10/19 15:09'),
          description: '',
          url: '/images/trafalgar_square.jpg',
          flag: 'gb-eng',
          tags: []
        },
        {
          title: 'Train Tracks',
          location: 'London, England',
          date: new Date('2020/10/12 00:39'),
          description: '',
          url: '/images/train_tracks_1.jpg',
          flag: 'gb-eng',
          tags: []
        },
        {
          title: 'Underground Station',
          location: 'London, England',
          date: new Date('2020/10/13 21:50'),
          description: '',
          url: '/images/underground.jpg',
          flag: 'gb-eng',
          tags: []
        },
        // {
        //   title: 'View of London',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/view_of_london_1.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        // {
        //   title: 'View of London',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/view_of_london_2.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        // {
        //   title: 'View of London',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/view_of_london_3.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        {
          title: 'View of London',
          location: 'London, England',
          date: new Date('2020/10/10 13:02'),
          description: '<p>View form southbank</p>',
          url: '/images/view_of_london_4.jpg',
          flag: 'gb-eng',
          tags: []
        },
        // {
        //   title: 'View of London',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/view_of_london_5.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        // {
        //   title: 'View of London',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/view_of_london_6.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        // {
        //   title: 'View of London',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/view_of_london_7.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        // {
        //   title: 'View of London',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/view_of_london_8.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        // {
        //   title: '',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/view_of_london_9.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        // {
        //   title: 'View of London',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/view_of_london_10.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        // {
        //   title: 'View of London',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/view_of_london_11.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        // {
        //   title: 'View of London',
        //   location: 'London, England',
        //   date: new Date(''),
        //   description: '',
        //   url: '/images/view_of_london_12.jpg',
        //   flag: 'gb-eng',
        //   tags: []
        // },
        {
          title: 'View of London',
          location: 'London, England',
          date: new Date('2020/10/10 14:44'),
          description: '<p>View from Tower Bridge</p>',
          url: '/images/view_of_london_13.jpg',
          flag: 'gb-eng',
          tags: ['Featured']
        },
        {
          title: 'View of the Shard',
          location: 'London, England',
          date: new Date('2020/10/10 14:09'),
          description: '',
          url: '/images/view_of_the_shard.jpg',
          flag: 'gb-eng',
          tags: []
        },
        {
          title: 'Vines',
          location: 'London, England',
          date: new Date('2020/10/15 16:38'),
          description: '',
          url: '/images/vines_1.jpg',
          flag: 'gb-eng',
          tags: ['Nature']
        },
        {
          title: 'Vines',
          location: 'London, England',
          date: new Date('2020/10/15 16:40'),
          description: '',
          url: '/images/vines_2.jpg',
          flag: 'gb-eng',
          tags: ['Nature']
        },
        {
          title: 'Walls International',
          location: 'Berlin, Germany',
          date: new Date('2020/03/08 10:27'),
          description: '<p>Walls International, Alexej Taranin, 1990. East Side Gallery.</p>',
          url: '/images/walls_international.jpg',
          flag: 'de',
          tags: ['Travel', 'Art']
        },
        {
          title: 'Worlds People',
          location: 'Berlin, Germany',
          date: new Date('2020/03/08 10:30'),
          description: '<p>Worlds People, Schamil Gimajew, 1990. East Side Gallery</p>',
          url: '/images/worlds_people.jpg',
          flag: 'de',
          tags: ['Travel', 'Art']
        },
        


      ],
      search: '',
      filter: '',
      sortBy: 'Date (desc)',
      isLoading: false,
    }

  }



  componentDidMount() {

    window.addEventListener('resize', this.updateSize);
    this.updateSize();

    var scrollToTopBtn = document.querySelector(".scrollToTopBtn")
    var rootElement = document.documentElement

    function handleScroll() {

      if ((rootElement.scrollTop > rootElement.clientHeight)) {

        scrollToTopBtn.style.display = "block"
      } else {

        scrollToTopBtn.style.display = "none"
      }
    }

    function scrollToTop() {

      rootElement.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    }
    scrollToTopBtn.addEventListener("click", scrollToTop)
    document.addEventListener("scroll", handleScroll)



  }

  // Update State
  updateFilter = (filter) => {
    this.setState({ filter: filter });
  }

  updateSortBy = (sortBy) => {
    this.setState({ sortBy: sortBy });
  }

  updateSearch = (search) => {
    this.setState({ search: search });
  }


  // Position Filter
  updateSize() {
    let totalWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    let containerWidth = document.getElementById('main').getBoundingClientRect().width;
    let filterWidth = document.getElementById('filter').getBoundingClientRect().width;
    let gutter = (totalWidth - containerWidth) / 2;
    let filterLeft = gutter + filterWidth + 7.5; // width of filter - padding
    document.getElementById('filter').style.left = 'calc( 100% - ' + filterLeft + 'px)';
  }



  render() {
    const { images, search, filter, sortBy } = this.state;

    const filteredImages = images
      .filter((image) => {
        return search === '' ? image : 
          image.title.toLowerCase().includes(search.toLowerCase()) ||
          image.location.toLowerCase().includes(search.toLowerCase()) ||
          image.description.toLowerCase().includes(search.toLowerCase());
      })
      .filter((image) => {
        return filter === '' ? image : image.tags.includes(filter);
      })
      .sort((a, b) => {

        switch (sortBy) {
          case 'Country (asc)':
            return a.location.substring(a.location.indexOf(", ") + 1) > b.location.substring(b.location.indexOf(", ") + 1) ? 1 : -1;
          case 'Country (desc)':
            return a.location.substring(a.location.indexOf(", ") + 1) < b.location.substring(b.location.indexOf(", ") + 1) ? 1 : -1;
          case 'Date (asc)':
            return a.date > b.date ? 1 : -1
          case 'Date (desc)':
            return a.date < b.date ? 1 : -1
          case 'Title (asc)':
            return a.title > b.title ? 1 : -1
          case 'Title (desc)':
            return a.title < b.title ? 1 : -1

          default:
            return 0;
        }

      })
      .map((image, index) => 
        <ImageCard key={index} image={image} />
      );


    return (
      <div id="main" className="container mx-auto p-2 mb-5">
        <Navbar />

        <div id="filter" className="filter">
          <Search updateSearch={(search) => this.updateSearch(search)} search={this.state.search} />
          <Filter updateFilter={(filter) => this.updateFilter(filter)} filter={this.state.filter} />
          <SortBy updateSortBy={(sortBy) => this.updateSortBy(sortBy)} sortBy={this.state.sortBy} />
          <hr />
        </div>

        <div className="my-1" style={{fontSize: '12px'}}>{filteredImages.length} results</div>
        
        <Masonry
          breakpointCols={ {default: 2, 1025: 1} }
          className="masonry-grid"
          columnClassName=""
          >
            {
              filteredImages.length === 0 ? 
                <h3 className="error-message">Your search - "{this.state.search}" - did not match any images.</h3> : 
                filteredImages
            }
        </Masonry>

            

        <div className="scrollToTopBtn">
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 10l7-7m0 0l7 7m-7-7v18"></path>
          </svg>
        </div>

      </div>
    );



  }
}

export default App;
