import React from 'react';
import LazyLoad from 'react-lazyload';

const ImageCard = ({ image }) => {

  return (

    <div className="masonry-grid-item rounded overflow-hidden mx-2 p-5">
      <div className="image-container">
        <LazyLoad height={500} offset={500} placeholder={<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>} once>
          <img src={image.url} alt={image.title} className="w-full" />
        </LazyLoad>
        <span className="image-title">{image.title}</span>

        <div className="image-information">
          <span className="image-location">
            <span className={`flag-icon flag-icon-${image.flag}`}></span>
            {image.location}
          </span>
          <span className="image-date">
            {
              image.date.toDateString() + ' ' +
              ("0" + image.date.getHours()).slice(-2) + ':' + 
              ("0" + image.date.getMinutes()).slice(-2)
            }
          </span>
          {image.tags.map((tag, index) => (
            <span key={index} className="image-tags">
              #{tag}
            </span>
          ))}
          <span className="image-description" dangerouslySetInnerHTML={{ __html: image.description }}></span>
        </div>

      </div>
    </div>

  )
}

export default ImageCard;
